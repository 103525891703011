<template>
  <div>
    <HeroView />
  </div>
</template>

<script>
import HeroView from "./HeroView2.vue"

export default {
  name: 'HomeView', 
  components: {
    HeroView,
  }
}
</script>
