

<template>
  <div class='relative z-0 h-full w-full' >        
    <div class="px-14 xl:px-36 absolute inset-0 text-black">
      <div class="grid md:grid-cols-3 gap-4 m-auto sm:mt-32 mt-28">      
        <div class="bg-transparent min-h-[100px] rounded-lg sm:col-span-1">        
          <h1 class="font-[54px] text-4xl">Don't Let Manual Data Entry Slow You Down. <br><b class="font-Poppins">Automate with AI</b></h1>
          <p class="sm:text-xl text-xl my-10 font-RobotoFlex normal-case text-justify" >Let our advanced AI and machine learning solutions streamline your document handling tasks. From mortgage and insurance documents to invoices and beyond, we classify text & extract critical data with precision, even from the most cluttered files.  

          <br><br>Experience seamless integration into your existing workflow, triggering actions and removing bottlenecks effortlessly.  

          <br><br>Focus on what matters, let us handle the noisy work. </p>
          <!-- <button type="button" class="text-white bg-gradient-to-br from-red-500 to-red-800 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-full text-2xl px-7 py-3 text-center me-2 mb-2">Request A Demo</button> -->
          <div class="xl:flex mb-10">
            <router-link to="/request-a-demo" type="button" class="text-white block mr-5 xl:mb-0 mb-5 bg-gradient-to-br from-slate-700 to-slate-800 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-full lg:min-w-64 min-w-52 lg:text-xl text-xl px-7 py-3 text-center uppercase">Request a demo</router-link>
            <router-link to="/insights" type="button" class="text-slate-700 uppercase block mr-5 xl:mb-0 mb-5 outline outline-2 from-slate-700 to-slate-800 hover:bg-gradient-to-bl hover:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-full lg:min-w-64 min-w-52 lg:text-xl text-xl px-7 py-3 text-center">See use cases</router-link>  
            
          </div>
        </div>
        <div id="default-carousel" class="relative w-full col-span-2 ml-14 " data-carousel="static">        
          <!-- Carousel wrapper -->
          <div class="relative h-64 overflow-hidden rounded-lg lg:min-h-[530px] ">
              <!-- Item 1 -->
              <div class="hidden duration-700 ease-in-out " data-carousel-item>
                  <!-- <img src="https://flowbite.com/docs/images/carousel/carousel-1.svg" class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..."> -->
                <video width="320" height="240"  controls class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" autoplay loop>
                <source  src="../../assets/videos/MQ_Slider_Video_1.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
                Your browser does not support the video tag.
            </video>
              </div>
              <!-- Item 2 -->
              <div class="hidden duration-700 ease-in-out" data-carousel-item>
                <video width="320" height="240"  controls class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" autoplay loop>
                <source  src="../../assets/videos/MQ_Slider_Video_2.mp4" type="video/mp4">
                <source src="movie.ogg" type="video/ogg">
                Your browser does not support the video tag.
                </video>
              </div>
              <!-- Item 3 -->
              <!-- <div class="hidden duration-700 ease-in-out" data-carousel-item>
                  <img src="https://flowbite.com/docs/images/carousel/carousel-3.svg" class="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="...">
              </div>              -->
          </div>
          <!-- Slider indicators -->
          <div class="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
              <button type="button" class="w-3 h-3 rounded-full" aria-current="true" aria-label="Slide 1" data-carousel-slide-to="0"></button>
              <button type="button" class="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 2" data-carousel-slide-to="1"></button>
              <!-- <button type="button" class="w-3 h-3 rounded-full" aria-current="false" aria-label="Slide 3" data-carousel-slide-to="2"></button> -->
          </div>
          <!-- Slider controls -->
          <button type="button" class="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
              <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                  <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
                  </svg>
                  <span class="sr-only">Previous</span>
              </span>
          </button>
          <button type="button" class="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
              <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                  <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                  </svg>
                  <span class="sr-only">Next</span>
              </span>
          </button>
        
      </div>
      
      <!-- <div class="grid lg:grid-cols-5 gap-5 m-4 lg:mt-10">             
        
        <div class="bg-transparent min-h-full rounded-lg lg:col-span-1 text-center">                    
          <img class="object-cover rounded-2xl shadow-xl dark:shadow-gray-800 mb-5" src="../../assets/square-placeholder.jpg" alt="image description">
          <span class="uppercase underline text-lg"> <a href="#" > Use Case 1</a></span>
        </div>
        <div class="bg-transparent min-h-full rounded-lg lg:col-span-1 text-center">                    
          <img class="object-cover rounded-2xl shadow-xl dark:shadow-gray-800 mb-5" src="../../assets/square-placeholder.jpg" alt="image description">
          <span class="uppercase underline text-lg"> <a href="#" > Use Case 2</a></span>
        </div>
        <div class="bg-transparent min-h-full rounded-lg lg:col-span-1 text-center">                    
          <img class="object-cover rounded-2xl shadow-xl dark:shadow-gray-800 mb-5" src="../../assets/square-placeholder.jpg" alt="image description">
          <span class="uppercase underline text-lg"> <a href="#" > Use Case 3</a></span>
        </div>
           
      </div> -->
    </div>
    </div>
  </div>  
</template>
<script setup>
import { onMounted } from 'vue'

onMounted(() => {
  import("flowbite");
});
</script>


