<template>
  <div>
    <Navbar/>
  
  
  <router-view/>

  <Footer>
    footer
  </Footer>
  </div>
</template>

<script>
import Navbar from "@/components/layouts/NavbarLayout";
import Footer from "@/components/layouts/FooterLayout";


export default{
  components:{
    Navbar,
    Footer,
  }
}
</script>
